import qs from 'qs';

import { newBackendHttp, newBackendHttpV2, newBackendHttpV3 } from '@/api/instances/rest';

import { IEditPhoneResponse, IUniversalResponse } from '@/types/new/backend-responses';
import {
  IBurnableCoins,
  ICustomerAllOrders,
  ICustomerDetailOrder,
  ICustomerNew,
} from '@/types/new/customers';

interface UpdateDto {
  name: string;
  email: string;
  phone: string;
  birthday: string;
  gender: string;
  sms_mailing: boolean;
  profile_picture: {};
}

type TSortBy = keyof Omit<ICustomerAllOrders, 'note'>;

type TSort = 'asc' | 'desc';

export class CustomerService {
  static async getById(
    customerId: number,
    accessToken?: string
  ): Promise<IUniversalResponse<ICustomerNew>> {
    const { data } = await newBackendHttp.get<IUniversalResponse<ICustomerNew>>(
      `/customers/${customerId}`,
      {
        ...(accessToken
          ? {
              headers: {
                Cookie: `accessToken=${accessToken}`,
              },
            }
          : {}),
      }
    );

    return data;
  }

  static async update(
    customerId: number,
    body: Partial<UpdateDto>,
    accessToken?: string
  ): Promise<IUniversalResponse<ICustomerNew>> {
    const { data } = await newBackendHttpV2.put<IUniversalResponse<ICustomerNew>>(
      `/customers/${customerId}`,
      { ...body },
      {
        ...(accessToken
          ? {
              headers: {
                Cookie: `accessToken=${accessToken}`,
              },
            }
          : {}),
      }
    );

    return data;
  }

  static async sendCodeToNewPhone(
    customerId: number,
    phone: string
  ): Promise<IEditPhoneResponse<{ message: string }>> {
    const { data } = await newBackendHttp.post<IEditPhoneResponse<{ message: string }>>(
      `/customers/${customerId}/edit-phone`,
      { phone }
    );

    return data;
  }

  static async editPhone(
    customerId: number,
    phone: string,
    code: string
  ): Promise<IEditPhoneResponse<{ message: string }>> {
    const { data } = await newBackendHttp.put<IEditPhoneResponse<{ message: string }>>(
      `/customers/${customerId}/edit-phone-complete`,
      {
        phone,
        code,
      }
    );

    return data;
  }

  static async getAllOrders(
    {
      skip = 0,
      take = 10,
      where,
    }: {
      skip?: number;
      take?: number;
      where?: Record<string, any>;
    },
    accessToken?: string
  ): Promise<
    IUniversalResponse<{
      total: number;
      items: ICustomerAllOrders[];
    }>
  > {
    const searchParams = qs.stringify(
      {
        ...(where ? { where } : {}),
        skip,
        take,
      },
      { encodeValuesOnly: true }
    );

    const { data } = await newBackendHttpV3.get<
      IUniversalResponse<{
        total: number;
        items: ICustomerAllOrders[];
      }>
    >(`/customers/orders?${searchParams}`, {
      ...(accessToken
        ? {
            headers: {
              Cookie: `accessToken=${accessToken}`,
            },
          }
        : {}),
    });

    return data;
  }

  static async getOrderById(
    id: number,
    accessToken?: string
  ): Promise<IUniversalResponse<ICustomerDetailOrder>> {
    const { data } = await newBackendHttpV3.get<IUniversalResponse<ICustomerDetailOrder>>(
      `/customers/orders/${id}`,
      {
        ...(accessToken
          ? {
              headers: {
                Cookie: `accessToken=${accessToken}`,
              },
            }
          : {}),
      }
    );

    return data;
  }

  static async getCurrentCountOfBurnableCoins(): Promise<
    IUniversalResponse<{
      coins: number;
      burnableCoins: IBurnableCoins[];
    }>
  > {
    const { data } = await newBackendHttp.get<
      IUniversalResponse<{
        coins: number;
        burnableCoins: IBurnableCoins[];
      }>
    >(`/customers/burnable-coins`);

    return data;
  }
}
