import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { CustomerService } from '@/services/customer.service';

export const customerApi = createApi({
  reducerPath: 'customerApi',
  baseQuery: fetchBaseQuery(),
  tagTypes: ['CustomerAllOrdersTag', 'CustomerOrderById'],
  endpoints: (build) => ({
    getAllOrders: build.query({
      providesTags: ['CustomerAllOrdersTag'],
      queryFn: async ({
        skip,
        take,
        where,
      }: {
        skip: number;
        take: number;
        where?: Record<string, any>;
      }) => {
        const { data } = await CustomerService.getAllOrders({ skip, take, where });
        return {
          data: data,
        };
      },
      onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(customerApi.util.invalidateTags(['CustomerOrderById']));
        });
      },
    }),
    getOrderById: build.query({
      providesTags: ['CustomerOrderById'],
      queryFn: async (id: number) => {
        const { data } = await CustomerService.getOrderById(id);
        return {
          data: data,
        };
      },
    }),
  }),
});

export const { useLazyGetOrderByIdQuery, useLazyGetAllOrdersQuery } = customerApi;
